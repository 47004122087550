const MoreLettersPill = ({ newLettersCount = 0, handleAction }) => (
  <>
    <button
      className={`sticky top-5 z-10 mx-auto block w-36 rounded-full bg-blue-500 py-1 text-center text-white shadow-lg ${newLettersCount > 0 ? 'visible' : 'invisible'}`}
      onClick={handleAction}
    >
      <span className="text-sm">
        <strong>{newLettersCount}</strong> new letters
      </span>
    </button>
  </>
);

const LoadingLetter = () => {
  return (
    <div className="card-flat content-around justify-between shadow">
      <div className="h-4 w-1/3 animate-pulse rounded bg-stone-100 dark:bg-stone-800"></div>
      <div className="h-4 w-1/6 animate-pulse place-items-end place-self-end rounded bg-stone-100 dark:bg-stone-800"></div>
      <div className="my-2 h-4 basis-1/2 animate-pulse rounded bg-stone-100 dark:bg-stone-800"></div>
      <div className="my-2 h-4 basis-full animate-pulse rounded bg-stone-100 dark:bg-stone-800"></div>
      <div className="mb-2 h-4 basis-full animate-pulse rounded bg-stone-100 dark:bg-stone-800"></div>
    </div>
  );
};

export { MoreLettersPill, LoadingLetter };
