import Img from 'next/image';

import { UserDate } from 'components/cards/UserDate';
import Anchor from 'components/typography/anchor';
import thumbsDown from 'images/icons/arrow-down.svg';
import thumbsUp from 'images/icons/arrow-up.svg';
import verifiedIcon from 'images/icons/badge-check.svg';
import type { PollVote } from 'lib/api/themis/types';
import { personPageLink } from 'components/cards/links';

interface PollProps {
  vote: PollVote;
}

export const Poll = ({ vote }: PollProps) => {
  const official = vote?.official;
  const officialName = `${official?.firstName} ${official?.lastName}`;
  const officialUrl = `${personPageLink({ bioguideId: official?.id, fullName: officialName })}`;
  const officialLink = (
    <Anchor
      className="anchor-text whitespace-nowrap"
      href={officialUrl}
      prefetch={false}
    >
      {officialName}
    </Anchor>
  );
  const hasEnoughOfficialInfo =
    official?.firstName && official?.lastName && official?.id;

  return (
    <div
      key={vote.id}
      id={vote.id}
      className="card-flat flex-row flex-nowrap items-center gap-4"
    >
      <section className="shrink-0 basis-6">
        {vote.direction === 'UP' ? (
          <Img
            src={thumbsUp}
            height="24"
            width="24"
            alt="Up Arrow"
            title="Approval"
            className="inline"
          />
        ) : (
          <Img
            src={thumbsDown}
            height="24"
            width="24"
            alt="Down Arrow"
            title="Disapproval"
            className="inline"
          />
        )}
      </section>
      <section className="flex flex-col gap-1">
        {vote.reason ? (
          <p className="font-optical-italic basis-full text-base text-stone-900 dark:text-stone-100">
            {vote.reason}
          </p>
        ) : null}
        <p className="flex basis-full flex-row text-xs sm:text-sm">
          {vote?.user?.verifiedVoter ? (
            <Img
              src={verifiedIcon}
              height="16"
              width="16"
              alt="Verified Voter"
              title="Verified Voter"
              className="mr-1 inline"
            />
          ) : null}
          <span>
            A {vote?.user?.verifiedVoter ? 'verified voter' : 'constituent'} in{' '}
            {vote.city}, {vote.state}{' '}
            {vote.direction === 'UP' ? 'approves' : 'does not approve'} of their
            job performance {hasEnoughOfficialInfo ? officialLink : null}{' '}
            <span className="hidden sm:inline">
              as of <UserDate date={vote.created} />
            </span>
          </span>
        </p>
      </section>
    </div>
  );
};
